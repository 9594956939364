import "../../css/blockthree.css";
function BlockThree({ propsBlockThree }) {
  return (
    <>
      <div className="blockthree" id="Interface">
        <div className="container">
          <div className="col-12">
            <div className="a-text-600-2 a-section-title a-text-center blockthree-title">
              {propsBlockThree &&
              propsBlockThree.arrBlockThreeOne &&
              propsBlockThree.arrBlockThreeOne.title
                ? propsBlockThree.arrBlockThreeOne.title
                : false}
            </div>
          </div>

          {propsBlockThree
            ? Object.keys(propsBlockThree).map((propsblockthreevalue, index) =>
                propsblockthreevalue ? (
                  <div key={index} className="blockthree-items">
                    {window.screen.width > "767" && index % 2 === 0 ? (
                      <div className=" row">
                        <div className="col-md-7">
                          <div className="blockthree-image">
                            <div className="a-text-400-2 a-section-text blockthree-text-img">
                              {
                                <img
                                  src={
                                    propsBlockThree[propsblockthreevalue].image
                                  }
                                />
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="blockthree-text-items">
                            <div className="blockthree-text">
                              <div className="a-text-600-4">
                                {propsBlockThree[propsblockthreevalue].subtitle}
                              </div>
                              <div className="a-text-400-2 a-section-text">
                                {
                                  propsBlockThree[propsblockthreevalue]
                                    .description
                                }
                              </div>
                              <div className="a-text-400-2 a-section-text">
                                {
                                  propsBlockThree[propsblockthreevalue]
                                    .descriptiontwo
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className=" row">
                        <div className="col-md-5">
                          <div className="blockthree-text-items">
                            <div className="blockthree-text">
                              <div className="a-text-600-4">
                                {propsBlockThree[propsblockthreevalue].subtitle}
                              </div>
                              <div className="a-text-400-2 a-section-text">
                                {
                                  propsBlockThree[propsblockthreevalue]
                                    .description
                                }
                              </div>
                              <div className="a-text-400-2 a-section-text">
                                {
                                  propsBlockThree[propsblockthreevalue]
                                    .descriptiontwo
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="blockthree-image">
                            <div className="a-text-400-2 a-section-text blockthree-text-img">
                              {
                                <img
                                  src={
                                    propsBlockThree[propsblockthreevalue].image
                                  }
                                />
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  false
                )
              )
            : false}
        </div>
      </div>
    </>
  );
}
export default BlockThree;
