import "../../css/blocktariff.css";
import YandexMetrika from "../system/metrics/YandexMetrika";

function BlockTariff({ propsTariff }) {
  return (
    <>
      <div className="blockfive" id="tariffBlock">
        <div className="blockfive-container">
          <div className="col-12">
            <div className="a-text-600-2 a-section-title a-text-center blockfive-title">
              {propsTariff &&
              propsTariff.arrBlockFiveMain &&
              propsTariff.arrBlockFiveMain.title
                ? propsTariff.arrBlockFiveMain.title
                : false}
            </div>
            <div className="a-fw-600 a-section-title a-text-center blockfive-subtitle">
              {propsTariff &&
              propsTariff.arrBlockFiveMain &&
              propsTariff.arrBlockFiveMain.subtitle
                ? propsTariff.arrBlockFiveMain.subtitle
                : false}
            </div>
          </div>
          <div className="blockfive-items">
            {propsTariff
              ? Object.keys(propsTariff.arrBlockFiveItem).map(
                  (tariffvalue, index) =>
                    tariffvalue ? (
                      <div key={index} className="blockfive-item p-4">
                        <div className="a-border-background blockfive-item-area">
                          <div className="blockfive-text a-relative">
                            <div className="blockfive-tariff-top-block ">
                              <div
                                className={`a-text-600-6 ${
                                  window.screen.width < "768" ? "pb-2" : "pb-4"
                                }`}
                              >
                                {
                                  propsTariff.arrBlockFiveItem[tariffvalue]
                                    .titleitem
                                }
                              </div>
                              <div className="a-text-400-3 a-section-text">
                                {
                                  propsTariff.arrBlockFiveItem[tariffvalue]
                                    .description
                                }
                              </div>
                              <div
                                className={`border_bg_b_c_three ${
                                  window.screen.width < 768 ? "" : "pb-4 pt-4"
                                } `}
                              >
                                <div className="a-text-600-1">
                                  {
                                    propsTariff.arrBlockFiveItem[tariffvalue]
                                      .price
                                  }
                                </div>
                                <div>
                                  {
                                    propsTariff.arrBlockFiveItem[tariffvalue]
                                      .pricetext
                                  }
                                </div>
                              </div>
                            </div>

                            <div
                              className="btn-block-tariff"
                              id={
                                "id_" +
                                propsTariff.arrBlockFiveItem[tariffvalue].id
                              }
                              onClick={() => {
                                window.open(
                                  `
                                  https://auth.aritin.ru/registration/?lenclick=tariff&${propsTariff.arrBlockFiveItem[tariffvalue].id}`,
                                  "_blank"
                                );
                                YandexMetrika(
                                  "reachGoal",
                                  `${propsTariff.arrBlockFiveItem[tariffvalue].id}`
                                );
                                YandexMetrika("reachGoal", "all_registration");
                              }}
                            >
                              Попробовать бесплатно
                            </div>
                            <div className="a-text-600-8 pt-4">
                              Тариф включает
                            </div>

                            <div className="a-text-400-3 a-section-text">
                              {propsTariff.arrBlockFiveItem[
                                tariffvalue
                              ].items.map((itemsvalue, index) =>
                                itemsvalue ? (
                                  <div key={index}>
                                    <div
                                      className={` blockfive-tariff-item ${
                                        window.screen.width < 768
                                          ? "pt-2"
                                          : "pt-3"
                                      }`}
                                    >
                                      <li> {itemsvalue}</li>
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )
                )
              : false}
          </div>
        </div>
      </div>
    </>
  );
}
export default BlockTariff;
