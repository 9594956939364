import "../../css/blocktwo.css";
function BlockTwo({ propsBlockTwo }) {
  return (
    <>
      <div className="blocktwo">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="a-text-600-2 a-section-title a-text-center">
                {propsBlockTwo && propsBlockTwo.title
                  ? propsBlockTwo.title
                  : false}
              </div>
            </div>
            <div className="col-md-6">
              <div className="blocktwo-text">
                <div className="a-text-400-2 a-section-text">
                  {propsBlockTwo && propsBlockTwo.description_one
                    ? propsBlockTwo.description_one
                    : false}
                </div>
                <div className="a-text-400-2 a-section-text">
                  {propsBlockTwo && propsBlockTwo.description_two
                    ? propsBlockTwo.description_two
                    : false}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="blocktwo-text">
                <div className="a-text-400-2 a-section-text">
                  {propsBlockTwo && propsBlockTwo.description_three
                    ? propsBlockTwo.description_three
                    : false}
                </div>
                <div className="a-text-400-2 a-section-text blocktwo-text-img">
                  {propsBlockTwo && propsBlockTwo.image ? (
                    <img src={propsBlockTwo.image} />
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default BlockTwo;
