import logo from "../../image/png/logo_white.png";
import "../../css/header.css";
import YandexMetrika from "./metrics/YandexMetrika";
import { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate, useLocation } from "react-router-dom";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  let expand = "xxl";
  if (window.screen.width <= 1200 && window.screen.width > 991) {
    expand = "xl";
  } else if (window.screen.width <= 991 && window.screen.width > 767) {
    expand = "lg";
  } else if (window.screen.width <= 767 && window.screen.width > 575) {
    expand = "md";
  } else if (window.screen.width <= 575) {
    expand = "sm";
  }
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if ($(event.target).hasClass("menu-item")) {
      setIsOpen(false);
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      navigate(`#${id}`);
    }
  };
  useEffect(() => {
    const hash = location.hash.substring(1); // Удаляем символ # из хеша
    if (hash) {
      const element = document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location.hash]);

  return (
    <header className="App-header">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="logo-description-block">
              <div>
                <img src={logo} className="app-header-logo" alt="logo" />
              </div>
              <div className="app-header-description">
                <div>ARITIN - система управление</div>
                <div>задачами и заявками</div>
              </div>
            </div>
          </div>
          <div className="col-lg-8">
            <div
              className={`burger-menu-block-items  ${
                (expand && expand === "xl") || (expand && expand === "xxl")
                  ? ""
                  : "menu-block-state"
              } ${isOpen && isOpen !== false ? "menu-block-state-active" : ""}`}
            >
              <div
                className={`${
                  (expand && expand === "xl") || (expand && expand === "xxl")
                    ? "row"
                    : "no-row"
                }`}
              >
                <div
                  className={`${
                    (expand && expand === "xl") || (expand && expand === "xxl")
                      ? "col-7"
                      : "no-col"
                  }`}
                >
                  <div className="app-header-menu-block-items">
                    <div className="app-header-menu-items">
                      <div className="a-text-600-2 logo-burger">ARITIN</div>
                      <div
                        className={`menu-items ${
                          (expand && expand === "xl") ||
                          (expand && expand === "xxl")
                            ? "a-flex-20"
                            : "pt-5"
                        } `}
                      >
                        <div
                          className="menu-item"
                          onClick={() => scrollToSection("tariffBlock")}
                        >
                          Тарифы
                        </div>

                        <div
                          className="menu-item"
                          onClick={() => scrollToSection("Interface")}
                        >
                          Интерфейс
                        </div>

                        <div
                          className="menu-item"
                          onClick={() => scrollToSection("tools")}
                        >
                          Инструменты
                        </div>

                        <div
                          className="menu-item"
                          onClick={() => scrollToSection("contact")}
                        >
                          Контакты
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  <div className="button-block">
                    <div
                      className="btn-item"
                      onClick={() => {
                        window.open(
                          "https://auth.aritin.ru/?lenclick=header_enter",
                          "_blank"
                        );
                        YandexMetrika("reachGoal", "header_enter");
                        YandexMetrika("reachGoal", "all_registration");
                      }}
                    >
                      Войти
                    </div>
                    <div
                      className="btn-item"
                      onClick={() => {
                        window.open(
                          "https://auth.aritin.ru/registration/?lenclick=header_registration",
                          "_blank"
                        );
                        YandexMetrika("reachGoal", "header_registration");
                        YandexMetrika("reachGoal", "all_registration");
                      }}
                    >
                      Регистрация
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(expand && expand === "lg") ||
          (expand && expand === "md") ||
          (expand && expand === "sm") ? (
            <div
              onClick={() => setIsOpen(!isOpen)}
              className={`burger ${isOpen !== false ? "active" : ""} `}
            >
              <div className="burger-button">
                <div className="middle"></div>
              </div>
            </div>
          ) : (
            false
          )}
        </div>
        <div>
          <YandexMetrika />
        </div>
      </div>
    </header>
  );
}
export default Header;
