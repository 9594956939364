import "../../css/footer.css";
import inRF from "../../image/img/made_in_rf.svg";
import YandexMetrika from "./metrics/YandexMetrika";
const Footer = () => {
  return (
    <>
      <div className="footer pb-5" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-6">
              <div className="pb-5 pt-4">
                <div className="border_bg_r_c_eleven">
                  <div className="a-text-600-2 a-section-title pb-5">
                    ARITIN
                  </div>
                  <div>
                    <div
                      className="pb-2 a-cursor"
                      onClick={() => {
                        window.open(
                          "https://auth.aritin.ru/?lenclick=footer_enter",
                          "_blank"
                        );
                        YandexMetrika("reachGoal", "footer_enter");
                      }}
                    >
                      Авторизоваться
                    </div>
                    <div
                      className="pb-2 a-cursor"
                      onClick={() => {
                        window.open(
                          "https://auth.aritin.ru/registration/?lenclick=footer_registration",
                          "_blank"
                        );
                        YandexMetrika("reachGoal", "footer_registration");
                        YandexMetrika("reachGoal", "all_registration");
                      }}
                    >
                      Регистрация
                    </div>
                  </div>

                  <div className="pt-5">
                    <div>&copy; 2024 Aritin. Москва</div>
                    <div>Сделано в России</div>
                    <div className="pt-2">
                      <a
                        href="https://aritin.ru/about/politica.php"
                        target="_blank"
                      >
                        Политика конфиденциальности
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-7 col-sm-6">
              <div className="pt-5 pb-2">
                <div className="a-text-600-6 pb-3">Контакты</div>
                <div className="footer-contact-item pt-3">
                  <div className="pb-2">
                    Телеграм:{" "}
                    <a href="https://t.me/@aritin_support" target="_blank">
                      aritin_support
                    </a>
                  </div>

                  <div className="pb-2">
                    Телефон: <a href="tel:+79671838380">+7 967 183 83 80 </a>
                  </div>
                  <div className="pb-2">
                    Почта: <a href="mailto:info@aritin.ru">info@aritin.ru</a>
                  </div>
                </div>
                <div className="pt-5">
                  Аритин - система управления рабочим процессом. Платформа
                  объединяет в себе инструменты для постановки задач, обработка
                  заявок, планирования, делегирования, коммуникации между
                  сотрудниками
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12">
              <div className="footer-in_rf pt-5 pb-5">
                <img src={inRF} className="in_rf" alt="in_RF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Footer;
