import { Routes, Route } from "react-router-dom";
import Main from "../page/Main";

function Routing() {
  return (
    <div className="content-routing">
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </div>
  );
}

export default Routing;
