import MainBanner from "../block/MainBanner";
import BlockTwo from "../block/blockTwo";
import BlockThree from "../block/blockThree";
import BlockFour from "../block/blockFour";
import BlockTariff from "../block/BlockTariff";
import BlockSix from "../block/blockSix";
import BlockQuestions from "../block/BlockQuestions";
import Footer from "../system/Footer";
import YandexMetrika from "../system/metrics/YandexMetrika";
import {
  FaListCheck,
  FaClock,
  FaCirclePlus,
  FaArrowsRotate,
  FaChartLine,
} from "react-icons/fa6";
import { BsHeadset } from "react-icons/bs";

const arrMain = {
  arrMainBanner: {
    title: "Управление заявками и задачами",
    description:
      "Ведите учет и управляйте заявками или задачами в офисе или на «удаленке» в комфортном для себя режиме.",
    video: "/images/blockbanner/logo_white.png",
    image: "/images/blocktwo/blocktwo.png",
  },
  arrBlockTwo: {
    title: "Служба поддержки и управление задачами",
    description_one:
      "Aritin — это платформа управления задачами, которая объединяет все необходимые инструменты для эффективного планирования, делегирования и коммуникации. Наша система разработана для того, чтобы сделать процесс работы вашей команды максимально простым и понятным. Система позволяет пользователям не только создавать задачи, но и использовать шаблоны для стандартных задач, что значительно экономит время.",
    description_two:
      "Aritin создан для того, чтобы упростить совместную работу и сделать ее более эффективной. С Aritin вы можете быстро формировать задачи, устанавливать дедлайны и распределять приоритеты, что делает процесс работы более организованным и целенаправленным. Система предоставляет возможность настройки ролей и прав доступа для каждого члена команды, что позволяет точно определить уровень ответственности и доступа к проектам и задачам, учитывая индивидуальные функции и полномочия в команде.",
    description_three:
      "Aritin помогает налаживать внутренние рабочие процессы, анализировать текущую ситуацию и эффективно планировать свою деятельность.",
    image: "/images/blocktwo/blocktwo.png",
  },
  arrBlockThree: {
    arrBlockThreeOne: {
      title: "Интерфейс",
      subtitle: "Список задач",
      description:
        "Наш список задач — это не просто инструмент, это ваш личный ассистент, который обеспечивает четкость и порядок. Все задачи собраны в одном месте, и вы можете легко отслеживать их статус, приоритеты и сроки выполнения. ",
      descriptiontwo:
        "Нет более пропущенных сроков или забытых задач — все под вашим контролем. Уведомления и напоминания гарантируют, что вы и ваша команда всегда будут вовремя информированы о важных задачах. Благодаря интуитивно понятному интерфейсу, каждый член команды может оптимизировать свой рабочий процесс, что приводит к повышению общей продуктивности.",
      image: "/images/blockthree/blockthreeone.jpg",
    },
    arrBlockThreeTwo: {
      subtitle: "Карточка задачи",
      description:
        "Каждая задача в нашей системе сопровождается детализированной карточкой, которая служит центральным элементом управления и координации работы. Описание задачи, чек-листы, исполнитель, соисполнитель, инициатор, наблюдатель, клиент, приоритет и тип задачи.",
      descriptiontwo:
        "Комментарии позволяют оставлять сообщения прямо в карточке задачи и облегчают коммуникацию между участниками и обеспечивает сохранение всей истории обсуждения в одном месте.",
      image: "/images/blockthree/blockthreetwo.png",
    },
    arrBlockThreeThree: {
      subtitle: "Внутренний чат",
      description:
        "В нашей системе управления задачами мы предусмотрели мощный инструмент коммуникации — внутренний чат. Он позволяет сотрудникам общаться друг с другом в режиме реального времени, обсуждать рабочие моменты, деляться идеями и находить решения задач.",
      descriptiontwo:
        "Личные чаты идеально подходят для тет-а-тет общения, позволяя обсудить конфиденциальные вопросы или быстро связаться с коллегой по конкретному вопросу. Групповые чаты обеспечивают эффективное взаимодействие всей команды. Создавайте группы по проектам, отделам или рабочим группам, чтобы все участники были в курсе обсуждений и могли вносить свой вклад в общее дело. Мы придаем большое значение безопасности и конфиденциальности ваших данных. Все сообщения зашифрованы, и у вас есть полный контроль над тем, кто может видеть ваши чаты и участвовать в них.",
      image: "/images/blockthree/blockthreethree.png",
    },
    arrBlockThreeFour: {
      subtitle: "Папки в карточке задачи",
      description:
        "В рамках нашей системы управления задачами мы предлагаем удобную функцию папок внутри каждой карточки задачи. Создавайте папки и называйте их в соответствии с содержимым, чтобы обеспечить легкое распознавание и доступ к необходимым файлам.",
      descriptiontwo:
        "Папки расположены непосредственно в карточке задачи, что делает их легкодоступными и удобными в использовании. Это исключает необходимость поиска файлов вне контекста задачи. Папки позволяют быстро делиться файлами с членами команды, обеспечивая эффективный обмен информацией и совместную работу над задачами.",
      image: "/images/blockthree/blockthreefour.png",
    },
  },
  arrBlockFour: {
    arrBlockFourMain: {
      title: "Зачем вашей кмпании сервис «ARITIN?»",
      subtitle:
        "Мы передаем Вам простой, удобный и понятный сервис для учета, обработки задач и эффективного взаимодействия сотрудников.",
    },
    arrBlockFourItem: {
      arrBlockFourOne: {
        titleitem: "Нет потерянных задач",
        description:
          "Задачи можно ставить через Telegram бота, а исполнитель будет уведомлен о новой задаче.",
        image: <FaListCheck />,
      },
      arrBlockFourTwo: {
        titleitem: "Экономия времени",
        description:
          "Быстрая и качественная обработка больших объемов задач, эффективное взаимодействие.",
        image: <FaClock />,
      },
      arrBlockFourThree: {
        titleitem: "Оперативная поддержка",
        description:
          "Профессиональная команда техподдержки всегда на связи для оперативных ответов и решений.",
        image: <BsHeadset />,
      },
      arrBlockFourFour: {
        titleitem: "Регулярные обновления",
        description:
          "Наш сервис непрерывно развивается, становятся доступны новые функции.",
        image: <FaArrowsRotate />,
      },
      arrBlockFourFive: {
        titleitem: "Рост рентабельности",
        description:
          "Повышается рентабельность бизнеса, сокращается отток клиентов.",
        image: <FaChartLine />,
      },
      arrBlockFourSix: {
        titleitem: "Увеличение производительности",
        description:
          "Благодаря автоматизации рутинных процессов возрастает скорость и качество работы.",
        image: <FaCirclePlus />,
      },
    },
  },
  arrTariff: {
    arrBlockFiveMain: {
      title: "ТАРИФЫ ARITIN",
      subtitle:
        "Здесь представлены базовые тарифы на использование системы. Также мы можем подобрать для вас индивидуальные условия.",
    },
    arrBlockFiveItem: {
      arrBlockFiveOne: {
        titleitem: "До 5 сотрудников",
        description: "Обучаем сотрудников, выполняем полную настройку системы",
        items: [
          "Управление задачами",
          "Управление заявками (+190₽)",
          "Telegram бот (+50₽)",
          "Чат между сотрудниками",
          "Поддержка 24/7",
          "Проведение обучения для сотрудников",
          "Персональный менеджер",
        ],
        price: "от ₽490",
        pricetext: "за пользователя в месяц",
        id: "tariff_one",
      },
      arrBlockFiveTwo: {
        titleitem: "До 10 сотрудников",
        description: "Обучаем сотрудников, выполняем полную настройку системы",
        items: [
          "Управление задачами",
          "Управление заявками (+160₽)",
          "Telegram бот (+40₽)",
          "Чат между сотрудниками",
          "Поддержка 24/7",
          "Проведение обучения для сотрудников",
          "Персональный менеджер",
        ],
        price: "от ₽440",
        pricetext: "за пользователя в месяц",
        id: "tariff_two",
      },
      arrBlockFiveThree: {
        titleitem: "До 50 сотрудников",
        description: "Обучаем сотрудников, выполняем полную настройку системы",
        items: [
          "Управление задачами",
          "Управление заявками (+120₽)",
          "Telegram бот (+30₽)",
          "Чат между сотрудниками",
          "Поддержка 24/7",
          "Проведение обучения для сотрудников",
          "Персональный менеджер",
        ],
        price: "от ₽390",
        pricetext: "за пользователя в месяц",
        id: "tariff_three",
      },
      arrBlockFiveFour: {
        titleitem: "От 50 сотрудников",
        description: "Обучаем сотрудников, выполняем полную настройку системы",
        items: [
          "Управление задачами",
          "Управление заявками (+90₽)",
          "Telegram бот (+20₽)",
          "Чат между сотрудниками",
          "Поддержка 24/7",
          "Проведение обучения для сотрудников",
          "Персональный менеджер",
        ],
        price: "от ₽290",
        pricetext: "за пользователя в месяц",
        id: "tariff_four",
      },
    },
  },
};
const arrBlockSix = [
  {
    main: [
      {
        title: "С сервисом Aritin все будет под контролем",
        description:
          "В ARITIN есть все инструменты для эффективной работы и управления бизнесом. Гибкие настройки и широкий функционал адаптируют ARITIN конкретно под Ваши потребности и задачи",
      },
    ],
    content: [
      {
        title: "Централизованная видимость и контроль",
        description:
          "Оптимизируйте работу для увеличения эффективности вашей организации",
        itemsvg1: <FaArrowsRotate />,
        itemsvg2: <FaChartLine />,
        itemsvg3: <FaCirclePlus />,
        itemtext1:
          "Настроивайте и адаптируйте систему конкретно под Ваши рабочие задачи благодаря понятному и функциональнуму интерфейсу.",
        itemtext2:
          "Каждую задачу можно привязать к новому или существующему проекту, назначить отвественного сотрудника, соисполнителя, наблюдателя и многое другое.",
        itemtext3:
          "Интеграция с Telegram позволит мгновенно ставить задачи через чат-бота в Telegram, а также уведомлять исполнителей о назначении им задач.",

        image: "/images/blocksix/screen.png",
      },
    ],
  },
];

const arrBlockQuestions = [
  {
    questionblock: [
      {
        question: "Как рассчитать стоимость подписки",
        answer:
          "Воспользуйтесь нашим калькулятором цен вверху этой страницы, чтобы определить стоимость вашей подписки. Просто введите, сколько у вас пользователей, и выберите цикл выставления счетов (годовой или ежемесячный).",
      },
      {
        question: "О пользователях и порядке выставления счетов за них",
        answer:
          "Пользователь - это тот, кто может войти на один из ваших сайтов Jira и кто существует в системе управления пользователями. Вы можете добавлять и удалять пользователей по мере изменений в вашей команде.",
      },
      {
        question: "Годовые подписки",
        answer:
          "Мы предлагаем годовые подписки на Jira. За эти подписки мы выставим вам счет за уровень, наиболее соответствующий количеству ваших пользователей.",
      },
    ],
  },
];

function Main() {
  function delayedStartThirtySeconds() {
    YandexMetrika("reachGoal", "time_on_the_site_30");
  }
  setTimeout(delayedStartThirtySeconds, 30000);

  function delayedStartSixtySeconds() {
    YandexMetrika("reachGoal", "time_on_the_site_60");
  }
  setTimeout(delayedStartSixtySeconds, 60000);

  function delayedStartNinetySeconds() {
    YandexMetrika("reachGoal", "time_on_the_site_90");
  }
  setTimeout(delayedStartNinetySeconds, 90000);

  return (
    <>
      <div>
        <MainBanner propsMainBanner={arrMain.arrMainBanner} />
        <BlockTwo propsBlockTwo={arrMain.arrBlockTwo} />
        <BlockThree propsBlockThree={arrMain.arrBlockThree} />
        <BlockFour propsBlockFour={arrMain.arrBlockFour} />
        <BlockTariff propsTariff={arrMain.arrTariff} id={"gfgf"} />
        <BlockSix propsBlockSix={arrBlockSix} />
        {/*<BlockQuestions propsBlockQuestions={arrBlockQuestions} /> */}
        <Footer />
      </div>
    </>
  );
}
export default Main;
