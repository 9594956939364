import "../../css/blocksix.css";
function BlockSix({ propsBlockSix }) {
  return (
    <>
      <div className="blocksix" id="tools">
        <div className="container">
          <div className="row">
            {propsBlockSix
              ? propsBlockSix.map((blocksixvalue, index) =>
                  blocksixvalue ? (
                    <div key={index}>
                      <div className="col-12">
                        <div className="pb-4">
                          {blocksixvalue.main
                            ? blocksixvalue.main.map((blocksixmain, index) =>
                                blocksixmain ? (
                                  <div key={index}>
                                    <div className="a-text-600-2 a-section-title a-text-center">
                                      {blocksixmain.title}
                                    </div>
                                    <div className="a-section-title a-text-center">
                                      {blocksixmain.description}
                                    </div>
                                  </div>
                                ) : (
                                  false
                                )
                              )
                            : false}
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="pt-4 pb-4">
                          {blocksixvalue.content
                            ? blocksixvalue.content.map((blocksixone, index) =>
                                blocksixone ? (
                                  <div key={index}>
                                    {window.screen.width > "767" &&
                                    index % 2 === 0 ? (
                                      <div className="blocksix-block-items">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div>
                                              <div className="a-text-600-6 a-section-title">
                                                {blocksixone.title}
                                              </div>
                                              <div className="">
                                                {blocksixone.description}
                                              </div>
                                            </div>

                                            <div className="blocksix-itemtext-block">
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg1}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext1}
                                                </div>
                                              </div>
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg2}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext2}
                                                </div>
                                              </div>
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg3}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext3}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="block-img-area">
                                              <div className="block-img">
                                                <img src={blocksixone.image} />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="blocksix-block-items">
                                        <div className="row">
                                          <div className="col-lg-6">
                                            <div>
                                              <img src={blocksixone.image} />
                                            </div>
                                          </div>
                                          <div className="col-lg-6">
                                            <div className="blocksix-description-title pt-4">
                                              <div className="">
                                                {blocksixone.description}
                                              </div>
                                              <div className="a-text-600-6 a-section-title">
                                                {blocksixone.title}
                                              </div>
                                            </div>

                                            <div className="blocksix-itemtext-block">
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg1}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext1}
                                                </div>
                                              </div>
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg2}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext2}
                                                </div>
                                              </div>
                                              <div className="blocksix-itemtext pt-4">
                                                <div className="blocksix-itemtext-svg">
                                                  {blocksixone.itemsvg3}
                                                </div>
                                                <div>
                                                  {blocksixone.itemtext3}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                ) : (
                                  false
                                )
                              )
                            : false}
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )
                )
              : false}
          </div>
        </div>
      </div>
    </>
  );
}
export default BlockSix;
