import "../../css/blockfour.css";

function BlockFour({ propsBlockFour }) {
  return (
    <>
      <div className="blockfour">
        <div className="container">
          <div className="col-12">
            <div className="a-text-600-2 a-section-title a-text-center blockfour-title">
              {propsBlockFour &&
              propsBlockFour.arrBlockFourMain &&
              propsBlockFour.arrBlockFourMain.title
                ? propsBlockFour.arrBlockFourMain.title
                : false}
            </div>
            <div className="a-fw-600 a-section-title a-text-center blockfour-subtitle">
              {propsBlockFour &&
              propsBlockFour.arrBlockFourMain &&
              propsBlockFour.arrBlockFourMain.subtitle
                ? propsBlockFour.arrBlockFourMain.subtitle
                : false}
            </div>
          </div>
          <div className="blockfour-items">
            {propsBlockFour
              ? Object.keys(propsBlockFour.arrBlockFourItem).map(
                  (propsblockfourvalue, index) =>
                    propsblockfourvalue ? (
                      <div key={index} className="blockfour-item p-3">
                        <div className="a-border-background p-4 blockfour-item-area">
                          <div className="blockfour-image">
                            <div className="a-text-400-2 a-section-text blockfour-text-img">
                              {
                                propsBlockFour.arrBlockFourItem[
                                  propsblockfourvalue
                                ].image
                              }
                            </div>
                          </div>

                          <div className="blockfour-text">
                            <div className="a-text-600-4">
                              {
                                propsBlockFour.arrBlockFourItem[
                                  propsblockfourvalue
                                ].titleitem
                              }
                            </div>
                            <div className="a-text-400-2 a-section-text">
                              {
                                propsBlockFour.arrBlockFourItem[
                                  propsblockfourvalue
                                ].description
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      false
                    )
                )
              : false}
          </div>
        </div>
      </div>
    </>
  );
}
export default BlockFour;
