import "../../css/mainbanner.css";
import YandexMetrika from "../system/metrics/YandexMetrika";
function MainBanner({ propsMainBanner }) {
  let expand = "xxl";
  if (window.screen.width <= 1200 && window.screen.width > 991) {
    expand = "xl";
  } else if (window.screen.width <= 991 && window.screen.width > 767) {
    expand = "lg";
  } else if (window.screen.width <= 767 && window.screen.width > 575) {
    expand = "md";
  } else if (window.screen.width <= 575) {
    expand = "sm";
  }

  return (
    <>
      <div className="mainbanner">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="mainbanner-text">
                <div className="a-text-600-1 a-section-title">
                  {propsMainBanner && propsMainBanner.title
                    ? propsMainBanner.title
                    : false}
                </div>
                <div className="a-text-400-1 a-section-text">
                  {propsMainBanner && propsMainBanner.description
                    ? propsMainBanner.description
                    : false}
                </div>
                <div
                  className="mainbanner-btn"
                  onClick={() => {
                    window.open(
                      "https://auth.aritin.ru/registration/?lenclick=main_registration",
                      "_blank"
                    );
                    YandexMetrika("reachGoal", "main_banner_registration");
                    YandexMetrika("reachGoal", "all_registration");
                  }}
                >
                  Попробовать бесплатно
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="mainbanner-video mainbanner-image-block ">
                <div className="mainbanner-image">
                  {propsMainBanner ? (
                    expand === "xl" || expand === "xxl" ? (
                      propsMainBanner.video ? (
                        <img src={propsMainBanner.video} />
                      ) : (
                        false
                      )
                    ) : propsMainBanner.image ? (
                      <img
                        className="image-if-expend"
                        src={propsMainBanner.image}
                      />
                    ) : (
                      false
                    )
                  ) : (
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default MainBanner;
